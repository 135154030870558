.PostList-module__HWa3Da__post-list {
  flex-direction: column;
  gap: 20px;
  width: 100%;
  display: flex;
}

.PostList-module__HWa3Da__post-list__list {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.PostList-module__HWa3Da__post-list__post-wrapper {
  flex-direction: column;
  display: flex;
}

.PostList-module__HWa3Da__post-list__rb-widget {
  display: none;
}

.PostList-module__HWa3Da__post-list__cr-widget {
  margin-top: 20px;
}

.PostList-module__HWa3Da__post-list__faq, .PostList-module__HWa3Da__post-list__seo-text {
  margin-top: 10px;
}

@media (max-width: 660px) {
  .PostList-module__HWa3Da__post-list__rb-widget {
    display: block;
  }
}

